import { annotationOption } from "../../types/annotationOption";
import { useForm, SubmitHandler, set } from "react-hook-form";
import { useState, useEffect } from "react";
import tokenizedAxios from "../../services/tokenizedAxios.service";
import { useCategoryContext } from "../../pages/categories";
import { notify } from "../notification";
interface annotationOptionProps {
  annotationOption: annotationOption;
  categoryId: number;
}

export default function AnnotationOption(props: annotationOptionProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <button
        className="button is-primary is-light is-small"
        onClick={() => {
          setShowModal(true);
        }}
      >
        {props.annotationOption.name}
      </button>
      {showModal && (
        <AnnotationOptionForm
          onClose={() => {
            setShowModal(false);
          }}
          annotationOptionId={props.annotationOption.id}
          categoryId={props.categoryId}
        />
      )}
    </>
  );
}

interface AnnotationOptionFormData {
  name: string;
  category_id: number;
  color: string;
  default_key_binding: string | null;
}

export function AnnotationOptionForm(props: {
  annotationOptionId?: number;
  onClose: () => void;
  categoryId: number;
}) {
  const { categories, fetchCategories } = useCategoryContext();
  const { register, setValue, handleSubmit } =
    useForm<AnnotationOptionFormData>();
  const [annotationOption, setAnnotationOption] =
    useState<annotationOption | null>(null);

  useEffect(() => {
    if (props.annotationOptionId) {
      fetchAnnotationOption(props.annotationOptionId);
    }
  }, [props.annotationOptionId]);

  const fetchAnnotationOption = (annotationOptionId: number) => {
    tokenizedAxios
      .get(`/annotation_options/${annotationOptionId}`)
      .then((response) => {
        setAnnotationOption(response.data);
        setValue("name", response.data.name);
        setValue("category_id", response.data.category_id);
        setValue("color", response.data.color);
        setValue("default_key_binding", response.data.default_key_binding);
      });
  };

  const deleteAnnotationOption = (id: number) => {
    if (
      window.confirm("Are you sure? This action cannot be undone.") === true
    ) {
      tokenizedAxios
        .delete(`/annotation_options/${id}`)
        .then(() => {
          props.onClose();
          fetchCategories();
          notify("Annotation option deleted successfully", "success");
        })
        .catch((error) => {
          notify(error.response.data.message[0], "error");
        });
    }
  };

  const onSubmit: SubmitHandler<AnnotationOptionFormData> = (
    requestData: any
  ) => {
    const requestMethod = annotationOption ? "put" : "post";
    const requestUrl = annotationOption
      ? `/annotation_options/${annotationOption.id}`
      : "/annotation_options";
    requestData.image_category_id = props.categoryId;
    tokenizedAxios[requestMethod](requestUrl, requestData)
      .then((response) => {
        props.onClose();
        fetchCategories();
        notify("Annotation option saved successfully", "success");
      })
      .catch((error) => {
        notify(error.response.data.message[0], "error");
      });
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={props.onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {props.annotationOptionId ? "Edit" : "Add"} Annotation Option
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={props.onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input className="input" {...register("name")} />
              </div>
            </div>
            <div className="field">
              <label className="label">Tag Color</label>
              <div className="control">
                <input className="input" {...register("color")} type="color" />
              </div>
            </div>
            <div className="field">
              <label className="label">Default key binding</label>
              <div className="control">
                <div className="select">
                  <select
                    {...register("default_key_binding")}
                    defaultValue={annotationOption?.default_key_binding || ""}
                  >
                    <option value="">None</option>
                    <option value="left_click">Left Click</option>
                    <option value="left_double_click">Double Left Click</option>
                    <option value="right_click">Right Click</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="field is-grouped">
              <div className="control">
                <button className="button is-link" type="submit">
                  {annotationOption ? "Edit" : "Add"}
                </button>
              </div>
              <div className="control">
                <button
                  className="button is-link is-light"
                  onClick={props.onClose}
                >
                  Cancel
                </button>
              </div>
              {annotationOption && (
                <div className="field">
                  <button
                    className="button is-danger"
                    type="button"
                    onClick={() => {
                      deleteAnnotationOption(annotationOption.id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}
