import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { notify } from "../components/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Logo from '../assets/images/Logo.png';

type PasswordForm = {
    password: string,
    passwordConfirmation: string
}

export default function SetPassword() {
    const navigate = useNavigate();
    const { token } = useParams();
    const [showForm, setShowForm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { register, handleSubmit, formState: { errors } } = useForm<PasswordForm>();
    const onSubmit: SubmitHandler<PasswordForm> = (data) => {
        setIsLoading(true);
        if (data.password !== data.passwordConfirmation){
            notify("Password confirmation and Password must match!", "error");
            setIsLoading(false);
            return
        }
        if (data.password.length < 6){
            notify("Password must be at least 6 characters long! ", "error");
            setIsLoading(false);
            return
        }
        axios.patch(process.env.REACT_APP_API_URL + "set_password/", {
            reset_token: token,
            password: data.password,
            password_confirmation: data.passwordConfirmation
        }).then((res) => {
            notify("Password sucessufly set, you can now login with your credentials and new password!", "success");
            navigate("/login");
        }).catch((err) => {
            notify(err.data.message, "error");
        })
        setIsLoading(false);
    };

    useEffect(() => {
        if (token?.length === 0) {
            navigate("/login", { replace: true });
        }
        axios.get(process.env.REACT_APP_API_URL + "validate_token/" + token).then((res) => {
            setShowForm(true)
        }).catch((err) => {
            notify("Password set link expired or invalid, please contact your administrator!", "error");
            navigate("/login", { replace: true });
        });
    }, [])

    return (
        <>
            {showForm &&
                <div className='columns is-centered section is-large'>
                    <form className='column is-two-fifths' onSubmit={handleSubmit(onSubmit)}>
                        <div className='has-text-centered '>
                            <img src={Logo} alt="Logo" width="300px" id="logo" />
                        </div>
                        <div className="field">
                            <label className='label' htmlFor="password">Password</label>
                            <div className="control has-icons-left">
                                <input className='input' type="password" {...register("password", { required: true })} />
                                <span className="icon is-small is-left">
                                    <FontAwesomeIcon icon={icon({ name: 'key' })} />
                                </span>
                                {errors.password && <span>This field is required</span>}
                            </div>
                        </div>
                        <div className="field">
                            <label className='label' htmlFor="password">Confirm password</label>
                            <div className="control has-icons-left">
                                <input className='input' type="password" {...register("passwordConfirmation", { required: true })} />
                                <span className="icon is-small is-left">
                                    <FontAwesomeIcon icon={icon({ name: 'key' })} />
                                </span>
                                {errors.password && <span>This field is required</span>}
                            </div>
                        </div>
                        <button type="submit" value='Login' className={`button is-primary is-fullwidth mt-5 ${isLoading ? 'is-loading' : ''}`}>
                            Set password
                        </button>
                    </form>
                </div>
            }
        </>
    )
}
