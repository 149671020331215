import { useForm, SubmitHandler } from "react-hook-form";
import { login } from '../services/auth.service';
import { notify } from './notification';
import { useNavigate } from "react-router-dom"
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from "react";
import Logo from '../assets/images/Logo.png';

type User = {
  email: string,
  password: string,
};

export default function LoginForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm<User>();
  const onSubmit: SubmitHandler<User> = data => {
    setIsLoading(true);
    login(data.email, data.password).then(
        (response) => {
            notify(response.message, "success");
            navigate("/", { replace: true });
        },
        ).catch((error) => {
            if (error.response) {
                notify(error.response.data.message, "error");
            }
            setIsLoading(false);
        }
    );
  };

  return (
    <div className='columns is-centered section is-large'>
      <form className='column is-two-fifths' onSubmit={handleSubmit(onSubmit)}>
        <div className='has-text-centered '>
          <img src={Logo} alt="Logo" width="300px" id="logo" />
        </div>
        <div className="field">
          <label className='label' htmlFor="email">Email</label>
          <div className="control has-icons-left">
            <input className='input' {...register("email", { required: true })} />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={icon({name: 'envelope'})} />
            </span>
            {errors.email && <span>This field is required</span>}
          </div>
        </div>
        <div className="field">
          <label className='label' htmlFor="password">Password</label>
          <div className="control has-icons-left">
            <input className='input' type="password" {...register("password", { required: true })} />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={icon({name: 'key'})} />
            </span>
            {errors.password && <span>This field is required</span>}
          </div>
        </div>
        <button type="submit" value='Login' className={`button is-primary is-fullwidth mt-5 ${isLoading ? 'is-loading' : ''}`}>
          Login
        </button>
      </form>
    </div>
  );
}

