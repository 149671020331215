import tokenizedAxios from '../services/tokenizedAxios.service'
import { useState, useEffect, createContext, Dispatch, SetStateAction, useContext } from 'react'
import { imageCategory } from '../types/imageCategory'
import { notify } from '../components/notification';
import AnnotationOption from '../components/categories/annotationOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AnnotationOptionForm } from '../components/categories/annotationOption'

interface CategoriesContextType {
    categories: Array<imageCategory>;
    fetchCategories: () => void;
}

const CategoryContext = createContext<CategoriesContextType>({
    categories: [],
    fetchCategories: () => { },
});

export default function Categories() {
    const [categories, setCategories] = useState<imageCategory[]>([]);
    const [newCategoryName, setNewCategoryName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [showNewModal, setShowNewModal] = useState<number | null>(null);

    const fetchCategories = () => {
        setLoading(true);
        tokenizedAxios.get("/image_categories").then((response) => {
            setCategories(response.data);
            setLoading(false);
        });

    };

    const handleNewCategoryNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewCategoryName(event.target.value);
    };

    const handleAddCategory = () => {
        if (newCategoryName === "") {
            notify("Category name cannot be empty", "error");
            return;
        };
        tokenizedAxios.post("/image_categories", { name: newCategoryName }).then(() => {
            fetchCategories();
            setNewCategoryName("");
            notify("Category added successfully", "success");
        }).catch((error) => {
            notify(error.response.data.message[0], "error");
        });
    };

    const deleteCategory = (categoryId: number) => {
        if (window.confirm("Are you sure? All related images and annotation data will no longer be available!") === true) {
            tokenizedAxios.delete(`/image_categories/${categoryId}`).then(() => {
                fetchCategories();
                notify("Category deleted successfully", "success");
            }).catch((error) => {
                notify(error.response.data.message[0], "error");
            });
        }
    };


    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <CategoryContext.Provider value={{ categories: categories, fetchCategories: fetchCategories }}>
        <div className="categories-container">
            <div className="category-form">
                <h1 className="title">Categories</h1>
                <div className="category-input">
                    <div className="control is-expanded">
                        <input
                            className="input"
                            placeholder="New category name"
                            value={newCategoryName}
                            onChange={handleNewCategoryNameChange}
                        />
                    </div>
                    <div className="control">
                        <button className="button is-primary" onClick={handleAddCategory}>Add Category</button>
                    </div>
                </div>
            </div>
            <div className={`category-list b-table ${loading ? "is-loading" : ""}`} id="category-list">
                {categories.length > 0 &&
                    (
                        <div>
                            {!loading && categories.map((category) => {
                                return (
                                    <div key={category.id} className="category-item">
                                        <h2 className="title is-6 category-name">{category.name}</h2>
                                        <div className="category-actions">
                                        <button className="button is-small is-danger" onClick={() => {deleteCategory(category.id)}}>Delete</button>
                                        <button className="button is-small is-primary is-light" onClick={() => { setShowNewModal(category.id) }}>
                                            <FontAwesomeIcon icon={icon({ name: "plus" })} />
                                        </button>

                                        {category.annotation_options.map((annotationOption) => {
                                            return (
                                                <AnnotationOption {...{annotationOption: annotationOption, categoryId: category.id}} key={annotationOption.id}/>
                                            )
                                        } )}

                                        </div>
                                        <hr></hr>
                                        { (showNewModal === category.id) && <AnnotationOptionForm {...{categoryId: category.id}} onClose={() => { setShowNewModal(null) }} key={category.id} /> }
                                    </div>
                                )
                            })}
                        </div>
                    )
                }
            </div>
        </div>
        </CategoryContext.Provider>
    )
}

export const useCategoryContext = () => useContext(CategoryContext);
