import { useState, useEffect, createContext, Dispatch, SetStateAction, useContext } from "react"
import { user } from '../types/user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import tokenizedAxios from "../services/tokenizedAxios.service"
import User from '../components/users/user'
import UserForm from "../components/users/userForm"

interface UsersContextType {
    users: Array<user>;
    setUsers: Dispatch<SetStateAction<Array<user>>>;
}

const UserContext = createContext<UsersContextType>({
    users: [],
    setUsers: () => { },
});

export default function Users() {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState<Array<user>>([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        tokenizedAxios.get("/users").then((response) => {
            setUsers(response.data);
            setIsLoading(false);
            setShowModal(false);
        });
    }, []);


    return (
        <UserContext.Provider value={{ users: users, setUsers: setUsers }}>
            <section className="hero is-primary">
                <div className="hero-body">
                    <p className="title">
                        Users
                    </p>
                    <p className="subtitle">
                        User management
                    </p>
                    <button className="button " onClick={() => { setShowModal(true) }}>
                        <span className="icon">
                            <FontAwesomeIcon icon={icon({ name: "plus" })} />
                        </span>
                        <span>Create User</span>
                </button>
                </div>

            </section>
            <div className="mt-3">

                {showModal && <UserForm onClose={() => { setShowModal(false) }} />}

                <section className="section">
                    <div className="container">
                        <div className={`b-table ${isLoading ? "is-loading" : ""}`}>
                            <div className="table-wrapper has-mobile-cards">
                                <table className="table is-striped is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Email</th>
                                            <th>First name</th>
                                            <th>Last name</th>
                                            <th>Role</th>
                                            <th>Created</th>
                                            <th>Updated</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map(user => (<User user={user} key={user.id} />))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </UserContext.Provider>
    )
}

export const useUserContext = () => useContext(UserContext);
