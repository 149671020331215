import './assets/stylesheets/main.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import MainRouter from './routes/main';
import Notification from './components/notification';
import 'bulma/css/bulma.min.css';


const router = MainRouter();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

document.title = "Cyto";
root.render(
  <>
    <RouterProvider router={router} />
    <Notification />
  </>
);
