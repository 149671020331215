import { fabric } from "fabric";
import "fabric-history";
import { useEffect, useRef } from "react";
import { useImageContext } from "../../pages/annotate";
import { useCanvasContext } from "../../pages/annotate";
import { useAnnotationSettingsContext } from "../../pages/annotate";
import { Image } from "fabric/fabric-impl";
import { addShape } from "./utils/shapeHelper";
import { wait } from "@testing-library/user-event/dist/utils";
import { notify } from "../notification";

declare module "fabric/fabric-impl" {
  interface Canvas {
    /* fabric-history */
    undo: (callback?: () => void) => void;
    redo: (callback?: () => void) => void;
    clearHistory: () => void;
    historyNextState: any;
    _historyNext: () => any;
    extraProps: any;
  }
}

const Canvas = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { currentImage } = useImageContext();
  const { setCurrentCanvas, currentCanvas } = useCanvasContext();
  const { annotationSettings } = useAnnotationSettingsContext();

  function applyFilters(){
    if (currentCanvas && currentImage) {
      const img = currentCanvas.backgroundImage as Image;
      if (img !== null) {
        const { brightness, contrast } = annotationSettings!;
        const filters: fabric.IBaseFilter[] = [];
        filters.push(
          new fabric.Image.filters.Brightness({
            brightness: brightness.toNumber(),
          })
        );
        filters.push(
          new fabric.Image.filters.Contrast({ contrast: contrast.toNumber() })
        );
        img.applyFilters(filters);
        currentCanvas.setBackgroundImage(
          img,
          currentCanvas.renderAll.bind(currentCanvas)
        );
      }
    }
  }

  useEffect(() => {
    applyFilters();
  }, [annotationSettings]);

  useEffect(() => {
    if (currentCanvas && currentImage){
      currentCanvas.on("history:undo", () => {
        applyFilters();
        console.log("undo");
      });
      currentCanvas.on("history:redo", () => {
        applyFilters();
        console.log("redo");
      });
    }
    console.log(currentImage);
  }, [currentImage]);

  useEffect(() => {
    let canvas: fabric.Canvas | null = null;
    fabric.Group.prototype.hasControls = false;

    const resizeCanvas = () => {
      if (canvas && currentImage) {
        const { width, height } = currentImage.metadata;
        const parentElement = canvasRef.current?.parentNode as HTMLElement;
        const parentWidth = parentElement.clientWidth;
        const parentHeight = parentElement.clientHeight;

        const scaleX = parentWidth / width;
        const scaleY = parentHeight / height;
        const zoomRatio = Math.min(scaleX, scaleY);

        let imageTextureSize = width > height ? width : height;
        if (imageTextureSize > fabric.textureSize) {
          fabric.textureSize = imageTextureSize;
          //The default texture limit of 2048 is not enough for this usecase
        }

        canvas.setWidth(width);
        canvas.setHeight(height);
        canvas.setZoom(zoomRatio);
        canvas.renderAll();
      }
    };

    if (currentImage) {
      const { width, height } = currentImage.metadata;

      canvas = new fabric.Canvas(canvasRef.current!, {
        width,
        height,
        fireMiddleClick: true,
        fireRightClick: true,
        stopContextMenu: true,
      });
      canvas.extraProps = ["selectable", "annotation_option_id"]


      fabric.Image.fromURL(
        process.env.REACT_APP_API_URL + currentImage.image_url,
        (img) => {
          canvas!.setBackgroundImage(img, canvas!.renderAll.bind(canvas));
          canvas!.historyNextState = canvas!._historyNext();
          if (currentImage.annotation_status === "in_progress" && Object.keys(currentImage.raw_canvas_data).length !== 0) {
            canvas!.loadFromJSON(currentImage.raw_canvas_data, () => {});
            notify("Loaded saved annotations", "info");
          }
          setCurrentCanvas(canvas as fabric.Canvas);
        },
        { crossOrigin: "anonymous" }
      );


      resizeCanvas();

      canvas?.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var zoom = canvas!.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        canvas!.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });

      canvas?.on("mouse:down", function (this: fabric.Canvas | any, opt) {
        var evt = opt.e;
        if (evt.button === 1) {
          // Check for middle click (button value of 1)
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        }
      });

      document.addEventListener("keydown", function (event) {
        // Deselect active object on escape key press
        if (event.key === "Escape") {
          if (canvas !== null) {
            canvas!.discardActiveObject();
            canvas!.renderAll();
          }
        }
      });

      canvas?.on("mouse:move", function (this: fabric.Canvas | any, opt) {
        if (this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });
      canvas?.on("mouse:up", function (this: fabric.Canvas | any, opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
      });
    }
    return () => {
      window.removeEventListener("resize", resizeCanvas);
      if (canvas) {
        canvas.dispose();
        canvas = null;
      }
    };
  }, [currentImage]);

  useEffect(() => {
    if (currentCanvas && currentImage && annotationSettings) {
      currentCanvas.on(
        "mouse:dblclick",
        function (this: fabric.Canvas | any, opt) {
          var evt = opt.e;
          if (annotationSettings.keyBindings !== undefined) {
            if (
              evt.button === 0 &&
              annotationSettings.keyBindings.leftDoubleClick !== undefined
            ) {
              // Check for left click (button value of 0)
              let params = {
                annotationOption:
                  annotationSettings.keyBindings.leftDoubleClick,
                shape: "circle", // TODO annotationSettings.shape
                canvas: currentCanvas,
                opt: opt,
              };
              addShape(params);
              return;
            }
          }
        }
      );

      currentCanvas.on("mouse:down", function (this: fabric.Canvas | any, opt) {
        var evt = opt.e;

        if (evt.button === 2 && evt.detail === 1) {
          currentCanvas.discardActiveObject();
          currentCanvas.requestRenderAll();
          return
        }
      });

      currentCanvas.on("mouse:up", function (this: fabric.Canvas | any, opt) {
        var evt = opt.e;

        if (!opt.isClick) {
          // prevents creating a shape when user selects an empty space
          return;
        }
        if (evt.button === 2 && evt.detail === 1) {
          if (annotationSettings.keyBindings !== undefined) {
            if (annotationSettings.keyBindings.rightClick !== undefined) {
              const activeObject = currentCanvas.getActiveObject();
              if (activeObject) {
                return;
              }
              if (currentCanvas.findTarget(opt.e, false)) {
                // prevents creation of overlapping shapes
                return;
              }
              let params = {
                annotationOption: annotationSettings.keyBindings.rightClick,
                shape: "circle", // TODO annotationSettings.shape
                canvas: currentCanvas,
                opt: opt,
              };
              addShape(params);
              return;
            }
          }
        }

        if (evt.button === 1 && evt.detail === 2) {
          // Check for middle click (button value of 1)
          const target = currentCanvas.findTarget(opt.e, false);

          if (target) {
            currentCanvas.remove(target);
            currentCanvas.requestRenderAll();
          }
        }
        wait(225).then(() => {
          // we have to wait and cancel the single click event if it turns out to be a double click
          if (annotationSettings.keyBindings !== undefined) {
            if (evt.detail === 2) {
              // Check for double click
              return;
            }
            if (evt.button === 0) {
              // Check for left click (button value of 0)
              if (annotationSettings.keyBindings.leftClick !== undefined) {
                let params = {
                  annotationOption: annotationSettings.keyBindings.leftClick,
                  shape: "circle", // TODO annotationSettings.shape
                  canvas: currentCanvas,
                  opt: opt,
                };
                addShape(params);
                return;
              }
            }
          }
        });
      });
    }
  }, [annotationSettings]);

  return <canvas ref={canvasRef} id="annotaion-canvas" />;
};

export default Canvas;
