import React, { useState } from "react";
import { useParams } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import { notify } from "../notification";
import tokenizedAxios from "../../services/tokenizedAxios.service";
import { useNavigate } from "react-router-dom";

type FormData = {
  images: FileList;
  image_category_id: number;
};

const ImageForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, handleSubmit } = useForm<FormData>();
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const images = new FormData();
    if (selectedFiles && selectedFiles.length > 1) {
      for (let i = 0; i < selectedFiles.length; i++) {
        images.append("images[]", selectedFiles[i]);
      }
    } else {
      images.append("image[image]", selectedFiles?.[0] as File);
    }
    images.append("image[image_category_id]", id!);

    tokenizedAxios
      .post("/images", images, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        notify("Image(s) created", "success");
        navigate("/images", { replace: true });
      })
      .catch((error) => {
        console.error(error);
        notify("Error creating image(s)", "error");
      });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;
      if (files.length > 20) {
        notify("You can only upload up to 20 images at a time", "error");
        return;
      }
      setSelectedFiles(files);
      const urls = [];
      for (let i = 0; i < files.length; i++) {
        urls.push(URL.createObjectURL(files[i]));
      }
      setPreviewUrls(urls);
    } else {
      setSelectedFiles(null);
      setPreviewUrls([]);
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Create Image</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => navigate("/images", { replace: true })}
          ></button>
        </header>
        <section className="modal-card-body">
          <p className="mb-3">You can upload up to 20 images at the time</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="file has-name">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  {...register("images", { required: true })}
                  onChange={handleFileChange}
                  multiple={true}
                  accept="image/*"
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">Choose file(s)…</span>
                </span>
                <span className="file-name">
                  {previewUrls.length > 0
                    ? `${selectedFiles?.length} file(s) selected`
                    : "No file chosen"}
                </span>
              </label>
            </div>
            <div>
              {previewUrls.length > 0 && (
                <div className="mt-3">
                  {previewUrls.map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt={`Preview ${index}`}
                      className="mr-2 mb-2"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="field pt-3">
              <div className="control">
                <button type="submit" className="button is-primary">
                  Create Image(s)
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ImageForm;
