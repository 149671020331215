import { Outlet } from "react-router-dom";
import Navbar from "./navbar";
import { createContext } from "react";
import { user } from "../types/user";
import { currentUser } from "../services/auth.service";

function App() {
  return (
    <CurrentUserContext.Provider value={currentUser()}>
      <Navbar />
      <Outlet />
    </CurrentUserContext.Provider>
  );
}

export default App;

export const CurrentUserContext = createContext<user | null>(null);
