import { Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  faTimes,
  faTrashAlt,
  faUndo,
  faRedo,
  faSave,
} from "@fortawesome/free-solid-svg-icons";

export default function Help(props: {
  onClose: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Help</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => props.onClose(false)}
          >
            <FontAwesomeIcon icon={icon({ name: "times" })} />
          </button>
        </header>
        <section className="modal-card-body">
          <div className="columns">
            <div className="column">
              <div className="box">
                <p className="title is-5">Zoom and Pan</p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    <b>ZOOM:</b> Use the mouse wheel to zoom in and out
                  </li>
                  <li>
                    <b>PAN:</b> Use the middle click to move the image around
                  </li>
                </ul>
              </div>
              <div className="box">
                <p className="title is-5">Removing Annotations</p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    Single annotation can be removed by double clicking the
                    middle mouse button on the annotation
                  </li>
                  <li>
                    Multiple annotations can be removed by dragging across canvas to select them and
                    pressing the trashcan button in the actions section
                    <FontAwesomeIcon
                      icon={icon({ name: "trash" })}
                      className="ml-1"
                    />
                  </li>
                </ul>
              </div>
              <div className="box">
                <p className="title is-5">Deselecting Annotations</p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    Selected annotations can be deselected by right clicking on
                    them or by pressing the escape key
                  </li>
                </ul>
              </div>
              <div className="box">
                <p className="title is-5">Saving Progress</p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>Progress is saved automatically every 2 minutes</li>
                  <li>
                    Progress can be saved manually by pressing the save button
                  </li>
                  <li>
                    Once the annotations are saved you can close the app and
                    continue your work on this image another time
                  </li>
                  <li>
                    <b>NOTE:</b> You can only have two active images at a time,
                    you have to save and finish one of them before you can start
                    working on a new one
                  </li>
                </ul>
              </div>
              <div className="box">
                <p className="title is-5">State history</p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    State is saved every time you interact with the annotations
                    - whether you delete or create a new annotation or resize an
                    exiting one
                  </li>
                  <li>
                    You can undo or redo your last action by pressing the undo
                    and redo buttons in the actions section
                    <FontAwesomeIcon
                      icon={icon({ name: "undo" })}
                      className="ml-2"
                    />
                    <FontAwesomeIcon
                      icon={icon({ name: "redo" })}
                      className="ml-1"
                    />
                  </li>
                </ul>
              </div>
              <div className="box">
                <p className="title is-5">Key bindings</p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    Your admin pre-assigned the annotation options to various
                    mouse actions, feel free to reassign them to fit your needs
                  </li>
                  <li>
                    You can have the same action assigned to multiple mouse
                    actions
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-space-between">
          <p className="has-text-grey-light is-size-7">
            Made with ❤️ using React, Fabric.js, and Ruby on Rails
          </p>
          <a href="#" className="is-link is-size-7">
            <FontAwesomeIcon
              icon={["fab", "github"]}
              style={{ marginRight: "5px" }}
            />
            GitHub
          </a>
        </footer>
      </div>
    </div>
  );
}
