import { fabric } from "fabric";
import "fabric-history";
import { useState, useEffect, useRef } from "react";
import { image } from "../../types/image";
import { notify } from "../notification";
import { useImageContext } from "../../pages/annotate";
import { useCanvasContext } from "../../pages/annotate";
import { useAnnotationSettingsContext } from "../../pages/annotate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import tokenizedAxios from "../../services/tokenizedAxios.service";
import Decimal from "decimal.js";
import Help from "./help";
import { Image } from "fabric/fabric-impl";
import AnnotationOption from "../categories/annotationOption";
import { annotationOption } from "../../types/annotationOption";

declare module "fabric/fabric-impl" {
  interface Canvas {
    /* fabric-history */
    undo: (callback?: () => void) => void;
    redo: (callback?: () => void) => void;
    clearHistory: () => void;
    historyNextState: any;
    _historyNext: () => any;
    extraProps: any;
  }
}

function exportCanvasToJSON(canvas: fabric.Canvas): any {
  return canvas.toJSON(["selectable", "annotation_option_id"]);
}

type keyBindingDropdown = {
  name: string;
  annotationOptionName: string;
  options: {
    name: string;
    id: number;
    color: string;
  }[];
  selected?: {
    name: string;
    id: number;
    color: string;
  };
};

export default function Sidebar() {
  const { currentImage, setCurrentImage } = useImageContext();
  const { currentCanvas, setCurrentCanvas } = useCanvasContext();
  const canvasRef = useRef<fabric.Canvas | undefined>(undefined);
  const { annotationSettings, setAnnotationSettings } = useAnnotationSettingsContext();
  const [brightness, setBrightness] = useState<Decimal>(new Decimal(0));
  const [contrast, setContrast] = useState<Decimal>(new Decimal(0));
  const [nextImage, setNextImage] = useState<number>(1);
  const [start, setStart] = useState<boolean>(false);
  const [disableActions, setDisableActions] = useState<boolean>(true);
  const [help, setHelp] = useState<boolean>(false);
  const [keyBindings, setKeyBindings] = useState<keyBindingDropdown[]>([]);

  function deleteSelectedCanvasObjects() {
    var selectedObjects: any = currentCanvas!.getActiveObject();
    if (selectedObjects === undefined || selectedObjects === null) {
      return;
    }
    if (selectedObjects.type === "activeSelection") {
      selectedObjects.canvas = currentCanvas;
      selectedObjects.forEachObject(function (obj: any) {
        currentCanvas!.remove(obj);
      });
    } else {
      var activeObject = currentCanvas!.getActiveObject();
      if (activeObject !== null) {
        currentCanvas!.remove(activeObject);
      }
    }
    currentCanvas!.discardActiveObject();
    currentCanvas!.renderAll();
  }

  function handleKeyBindingChange(e: any, keyBinding: keyBindingDropdown) {
    const selectedOption = keyBinding.options.find(
      (option) => option.id === parseInt(e.target.value)
    );
    if (selectedOption) {
      keyBinding.selected = selectedOption;
      const newKeyBindings = [...keyBindings];
      setKeyBindings(newKeyBindings);
    } else {
      keyBinding.selected = undefined;
      const newKeyBindings = [...keyBindings];
      setKeyBindings(newKeyBindings);
    }
  }

  function applyFilters() {
    if (currentCanvas && currentImage && annotationSettings) {
      const img = currentCanvas.backgroundImage as Image;
      if (img !== null) {
        const { brightness, contrast } = annotationSettings!;
        const filters: fabric.IBaseFilter[] = [];
        filters.push(
          new fabric.Image.filters.Brightness({
            brightness: brightness.toNumber(),
          })
        );
        filters.push(
          new fabric.Image.filters.Contrast({ contrast: contrast.toNumber() })
        );
        img.applyFilters(filters);
        currentCanvas.setBackgroundImage(
          img,
          currentCanvas.renderAll.bind(currentCanvas)
        );
      }
    }
  }

  function saveProgress() {
    if (canvasRef.current && currentImage) {
      const canvasData = exportCanvasToJSON(canvasRef.current);
      console.log(canvasData);
      tokenizedAxios
        .patch(`/images/${currentImage.id}/save_progress/`, {
          raw_canvas_data: canvasData,
        })
        .then((res) => {
          notify("Progress saved", "success");
        });
    }
  }

  async function saveAndFinish() {
    if (currentCanvas && currentImage) {
      if (
        window.confirm(
          "Are you sure you want to finish annotating this image?."
        ) === true
      ) {
        const canvasData = exportCanvasToJSON(currentCanvas);
        try {
          await tokenizedAxios.patch(`/images/${currentImage.id}/save_progress/`, {
            raw_canvas_data: canvasData,
          });
          await tokenizedAxios.patch(`/images/${currentImage.id}/complete_annotation/`);
          canvasRef.current?.clear();
          setCurrentCanvas(undefined);
          setAnnotationSettings(undefined);
          setKeyBindings([]);
          setDisableActions(true);
          setStart(false);
          notify("Image annotated 😀", "success");
        } catch (error) {
          console.error(error);
          notify("Error completing annotation", "error");
        }
      }
    }
  }

  useEffect(() => {
    canvasRef.current = currentCanvas;
  }, [currentCanvas]);

  useEffect(() => {
    if (currentCanvas && currentImage) {
      const interval = setInterval(() => {
        saveProgress();
      }, 120_000);
      return () => clearInterval(interval);
    }
  }, [currentCanvas, currentImage]);

  useEffect(() => {
    if (annotationSettings) {
      const newKeyBindings = [...keyBindings];
      const newAnnotationSettings: any = { ...annotationSettings };
      if (newAnnotationSettings.keyBindings === undefined) {
        newAnnotationSettings.keyBindings = {};
      }
      newKeyBindings.forEach((keyBinding) => {
        if (keyBinding.selected) {
          newAnnotationSettings.keyBindings[keyBinding.annotationOptionName] =
            keyBinding.selected;
        } else if (keyBinding.selected === undefined) {
          newAnnotationSettings.keyBindings[keyBinding.annotationOptionName] =
            undefined;
        }
      });
      setAnnotationSettings(newAnnotationSettings);
    }
  }, [currentCanvas, keyBindings]);

  useEffect(() => {
    if (currentImage) {
      const keyBindingOptions = [
        ["leftClick", "Left click"],
        ["rightClick", "Right click"],
        ["leftDoubleClick", "Double left click"],
      ];
      const annotationOptionsDefaultKeyBindings = {
        "leftClick": Array<annotationOption>(),
        "rightClick": Array<annotationOption>(),
        "leftDoubleClick": Array<annotationOption>(),
      };

      currentImage.image_category.annotation_options.forEach((option) => {
        if (option.default_key_binding === "left_click") {
          annotationOptionsDefaultKeyBindings["leftClick"].push(option);
        } else if (option.default_key_binding === "right_click") {
          annotationOptionsDefaultKeyBindings["rightClick"].push(option);
        } else if (option.default_key_binding === "left_double_click") {
          annotationOptionsDefaultKeyBindings["leftDoubleClick"].push(option);
        }
      });

      const keyBindings: keyBindingDropdown[] = [];
      keyBindingOptions.forEach((option) => {
        keyBindings.push({
          name: option[1],
          annotationOptionName: option[0],
          options: currentImage.image_category.annotation_options,
          selected: annotationOptionsDefaultKeyBindings[option[0] as keyof typeof annotationOptionsDefaultKeyBindings][0],
        });
      });
      setKeyBindings(keyBindings);
    }
  }, [currentImage]);

  useEffect(() => {
    const confirm =
      nextImage > 1
        ? window.confirm(
            "Are you sure? You will lose all unsaved progress on the current image."
          )
        : true;
    if (!confirm) {
      return;
    }
    const apiUrl = currentImage
      ? `/images/next_image/?current_image_id=${currentImage.id}`
      : `/images/next_image/`;

    if (start) {
      tokenizedAxios.get(apiUrl).then((res) => {
        if (res.data.length === 0) {
          notify("There are currently no images left to annotate", "info");
          setDisableActions(true);
        } else {
          setCurrentImage(res.data);
          setDisableActions(false);
          setBrightness(new Decimal(0));
          setContrast(new Decimal(0));
        }
      }).catch((error) => {
        console.error(error);
        notify("Error getting next image", "error");
      });
    }
  }, [start, nextImage]);

  useEffect(() => {
    setAnnotationSettings({
      brightness: brightness,
      contrast: contrast,
      keyBindings: annotationSettings?.keyBindings ?? undefined,
    });
  }, [brightness, contrast]);
  return (
    <>
      <aside className="sidebar">
        <div className="controls">
          <figure className="action-group">
            <span className="name">Brightness</span>
            <div className="actions">
              <button
                disabled={!start || disableActions}
                className="button is-primary"
                onClick={() => setBrightness(brightness.minus(0.1))}
              >
                <FontAwesomeIcon icon={icon({ name: "minus" })} />
              </button>
              <span>{brightness.toFixed(1)}</span>
              <button
                disabled={!start || disableActions}
                className="button is-primary"
                onClick={() => setBrightness(brightness.plus(0.1))}
              >
                <FontAwesomeIcon icon={icon({ name: "plus" })} />
              </button>
            </div>
          </figure>

          <figure className="action-group">
            <span className="name">Contrast</span>
            <div className="actions">
              <button
                disabled={!start || disableActions}
                className="button is-primary"
                onClick={() => setContrast(contrast.minus(0.1))}
              >
                <FontAwesomeIcon icon={icon({ name: "minus" })} />
              </button>
              <span>{contrast.toFixed(1)}</span>
              <button
                disabled={!start || disableActions}
                className="button is-primary"
                onClick={() => setContrast(contrast.plus(0.1))}
              >
                <FontAwesomeIcon icon={icon({ name: "plus" })} />
              </button>
            </div>
          </figure>
          <figure className="action-group">
            <span className="name">Actions</span>
            <div className="actions">
              <button
                disabled={!start || disableActions}
                className="button is-primary"
                onClick={() => currentCanvas!.undo(applyFilters)}
              >
                <FontAwesomeIcon icon={icon({ name: "rotate-left" })} />
              </button>
              <button
                disabled={!start || disableActions}
                className="button is-primary"
                onClick={() => currentCanvas!.redo(applyFilters)}
              >
                <FontAwesomeIcon icon={icon({ name: "rotate-right" })} />
              </button>
              <button
                disabled={!start || disableActions}
                className="button is-warning"
                onClick={() => currentCanvas!.redo()}
              >
                <FontAwesomeIcon icon={icon({ name: "draw-polygon" })} />
              </button>
              <button
                disabled={!start || disableActions}
                className="button is-danger"
                onClick={() => deleteSelectedCanvasObjects()}
              >
                <FontAwesomeIcon icon={icon({ name: "trash" })} />
              </button>
              <button
                disabled={!start || disableActions}
                className="button is-info"
                onClick={() => setHelp(true)}
              >
                <FontAwesomeIcon icon={icon({ name: "circle-info" })} />
              </button>
            </div>
          </figure>
          {start && (
            <figure className="action-group mt-5">
              <span className="name">Key bindings</span>
              {currentImage &&
                keyBindings.map((keyBinding, index) => (
                  <div className="actions horizontal" key={index}>
                    <span>{keyBinding.name}</span>
                    <div className="action-subgroup">
                      <div className="select">
                        <select
                          className="select"
                          onChange={(e) => {
                            handleKeyBindingChange(e, keyBinding);
                          }}
                          value={keyBinding.selected?.id}
                        >
                          <option value={0} key={0}>
                            None
                          </option>
                          {keyBinding.options.map((option) => (
                            <option value={option.id} key={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <span
                        className="color-circle"
                        style={{ backgroundColor: keyBinding.selected?.color }}
                      ></span>
                    </div>
                  </div>
                ))}
            </figure>
          )}
        </div>
        <div className="image-actions">
          {!start ? (
            <button
              className="button is-primary"
              onClick={() => setStart(true)}
            >
              Start annotating
            </button>
          ) : (
            <>
              <button
                disabled={!start || disableActions}
                className="button is-primary"
                onClick={() => setNextImage(nextImage + 1)}
                //TODO: Reset contrast and brightness
              >
                Next image
              </button>
              <div className="save">
                <button
                  disabled={!start || disableActions}
                  className="button is-info"
                  onClick={saveProgress}
                >
                  Save
                </button>
                <button
                  disabled={!start || disableActions}
                  className="button is-primary"
                  onClick={saveAndFinish}
                >
                  Save and finish
                </button>
              </div>
            </>
          )}
        </div>
      </aside>
      {help && <Help onClose={setHelp} />}
    </>
  );
}
