import { createBrowserRouter, Navigate } from "react-router-dom";
import App from '../components/app';
import Annotate from '../pages/annotate';
import Users from '../pages/users';
import Images from '../pages/images';
import ImageForm from '../components/images/imageForm';
import SetPassword from "../pages/setPassword";

import ErrorPage from '../pages/error';
import LoginPage from '../pages/login';
import { userSignedIn } from "../services/auth.service";
import Categories from "../pages/categories";

function PrivateRoute({ element, ...rest }: any) {
  const isAuthenticated = userSignedIn();
  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
}

export default function router() {

  return createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoute element={<App />} />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Annotate />,
        },
        {
          path: "/users",
          element: <Users />,
        },
        {
          path: "/images",
          element: <Images />,
        },
        {
          path: "/images/:id/edit",
          element: <ImageForm />,
        },
        {
          path: "/images/new/:id",
          element: <ImageForm />,
        },
        {
          path: "/categories",
          element: <Categories />,
        },
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/set_password/:token",
      element: <SetPassword />,
      errorElement: <ErrorPage />
    }
  ])
};
