import { useForm, SubmitHandler } from "react-hook-form";
import { notify } from '../notification';
import { useState, useEffect } from "react";
import { user } from "../../types/user";
import { useUserContext } from "../../pages/users";
import tokenizedAxios from "../../services/tokenizedAxios.service";

interface UserFormProps {
    userId?: number;
    onClose: () => void;
}

interface UserFormData {
    first_name: string;
    last_name: string;
    email: string;
    is_admin: boolean;
}

export default function UserForm(props: UserFormProps) {
    const {register, setValue, handleSubmit} = useForm<UserFormData>();
    const [user, setUser] = useState<user | null>(null);
    const {users, setUsers} = useUserContext();
    const fetchUser = (userId: number) => {
        tokenizedAxios.get(`/users/${userId}`).then((response) => {
            setUser(response.data);
            setValue("first_name", response.data.first_name);
            setValue("last_name", response.data.last_name);
            setValue("email", response.data.email);
            setValue("is_admin", response.data.is_admin);
        });
    };
    useEffect(() => {
        if (props.userId) {
            fetchUser(props.userId);
        }
    }, [props.userId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit: SubmitHandler<UserFormData> = (requestData) => {
        const requestMethod = user ? "put" : "post";
        const requestUrl = user ? `/users/${user.id}` : "/users";
        tokenizedAxios[requestMethod](requestUrl, requestData)
            .then((response) => {
                props.onClose();
                if (user) {
                    const updatedUsers = users.map((u) => {
                        if (u.id === user.id) {
                            return {
                                ...u,
                                ...requestData,
                            };
                        }
                        return u;
                    });
                    setUsers(updatedUsers);
                }
                else {
                    setUsers([response.data, ...users]);
                };
                notify("User saved successfully", "success");

            })
            .catch(() => {
                notify("Error saving user", "error");
            });
    };

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={props.onClose}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.userId ? "Edit User" : "Create User"}</p>
                    <button className="delete" aria-label="close" onClick={props.onClose}></button>
                </header>
                <section className="modal-card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="field">
                            <label className="label">First Name</label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    id="first_name"
                                    placeholder="First Name"
                                    defaultValue=""
                                    {...register("first_name")}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Last Name</label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Last Name"
                                    defaultValue=""
                                    {...register("last_name")}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Admin</label>
                            <div className="control">
                                <input
                                    className="checkbox"
                                    id="is_admin"
                                    type="checkbox"
                                    {...register("is_admin")}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Email</label>
                            <div className="control">
                                <input
                                    className="input"
                                    type="email"
                                    placeholder="Email"
                                    {...register("email")}
                                />
                            </div>
                        </div>
                        <div className="field is-grouped">
                            <div className="control">
                                <button className="button is-link" type="submit">
                                    Save
                                </button>
                            </div>
                            <div className="control">
                                <button className="button is-link is-light" onClick={props.onClose}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    );
}
