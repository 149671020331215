import { logout } from "../services/auth.service";
import { NavLink, useNavigate } from "react-router-dom";
import { notify } from "./notification";
import Logo from "../assets/images/Logo.png";
import { useContext } from "react";
import {CurrentUserContext} from  "../components/app"

export default function Navbar() {
  const navigate = useNavigate();
  const user = useContext(CurrentUserContext)

  const handleLogout = () => {
    logout()
      .then((response) => {
        notify(response.data.message, "success");
        navigate("/login", { replace: true });
      })
      .catch((error) => {
        localStorage.removeItem("user");
        localStorage.removeItem("jwt");
        notify("You have been logged out", "success");
        navigate("/login", { replace: true });
      });
  };

  return (
    <nav
      className="navbar is-light is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item ml-1">
          <img
            src={Logo}
          />
        </a>
      </div>
      <div className="navbar-menu ml-1">
        <div className="navbar-start">
          <NavLink to={"/"} className="navbar-item">
            Annotate
          </NavLink>
          { user?.is_admin &&
            <>
              <NavLink to={"/users"} className="navbar-item">
                Users
              </NavLink>
              <NavLink to={"/images"} className="navbar-item">
                Images
              </NavLink>
              <NavLink to={"/categories"} className="navbar-item">
                Categories
              </NavLink>
            </>
          }
        </div>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">
          <span
            className="button is-primary is-outlined"
            onClick={handleLogout}
          >
            Logout{" "}
          </span>
        </div>
      </div>
    </nav>
  );
}
