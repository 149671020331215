import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link, useNavigate } from "react-router-dom";
import { image } from "../types/image";
import { imageCategory } from "../types/imageCategory";
import { notify } from "../components/notification";
import tokenizedAxios from "../services/tokenizedAxios.service";
import Pagination from "react-js-pagination";

const strftime = require('strftime');


export default function Images() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [categories, setCategories] = useState<Array<imageCategory>>([]);
  const [images, setImages] = useState<image[]>([]);
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  function downloadExportData() {
    setExportLoading(true);
    const category = categories.find((category) => category.id === selectedCategory);
    const filename = `${strftime("%Y/%m/%d", new Date())} - ${category?.name}.json`;
    tokenizedAxios
      .get(`/export_data/${selectedCategory}`)
      .then((response) => {
        const blob = new Blob([JSON.stringify(response.data)], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
        notify("Error exporting data", "error");
      })
      .finally(() => {
        setExportLoading(false);
      });
  }

  function handlePageChange(pageNumber: number) {
    setCurrentPage(pageNumber);
    tokenizedAxios
      .get(`/images?image_category_id=${selectedCategory}&page=${pageNumber}`)
      .then((response) => {
        console.log(response.data);
        setImages(response.data.images);
        setTotalPages(response.data.total_pages);
        setCurrentPage(response.data.current_page);
      });
  };

  useEffect(() => {
    tokenizedAxios.get("/image_categories").then((response) => {
      if (response.data.length === 0) {
        notify(
          "You have to create at least one image category before you can add images",
          "info"
        );
        navigate("/categories", { replace: true });
        return;
      }
      setCategories(response.data);
      setSelectedCategory(response.data[0].id);
    });
  }, []);

  useEffect(() => {
    tokenizedAxios
      .get(`/images?image_category_id=${selectedCategory}`)
      .then((response) => {
        console.log(response.data);
        setImages(response.data.images);
        setTotalPages(response.data.total_pages);
        setCurrentPage(response.data.current_page);
      });
  }, [selectedCategory]);

  return (
    <>
      <section className="images-hero hero is-primary">
        <div className="hero-body">
          <p className="title">Images</p>
          <p className="subtitle">Uploaded images</p>
        </div>
      </section>
      <div className="columns">
        <div className="images-menu column is-one-fifth is-flex justify-content-center ">
          <aside className="category-menu menu p-6">
            <p className="menu-label">Categories</p>
            <ul className="menu-list">
              {categories.map((category) => (
                <li key={category.id}>
                  <Link
                    to="#"
                    className={category.id === selectedCategory ? "is-active" : ""}
                    onClick={() => setSelectedCategory(category.id)}
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </aside>

        </div>
        <div style={{ position: 'fixed', bottom: '0', right: 0, backgroundColor: 'white', zIndex: 1000, display: 'flex', justifyContent: 'flex-end', padding: '10px 40px', width: '100vw'}}>
  <nav
    className="pagination"
    role="navigation"
    aria-label="pagination"
  >
    <Pagination
      activePage={currentPage}
      itemsCountPerPage={18} // replace with the number of items per page
      totalItemsCount={totalPages * 18} // replace with the total number of items
      pageRangeDisplayed={5}
      prevPageText="Previous"
      nextPageText="Next"
      firstPageText="First"
      lastPageText="Last"
      linkClass="pagination-link"
      linkClassFirst="pagination-previous"
      linkClassPrev="pagination-previous"
      linkClassNext="pagination-next"
      linkClassLast="pagination-next"
      activeLinkClass="is-current"
      hideFirstLastPages={false}
      onChange={handlePageChange}
    />
  </nav>
</div>
        <div className="column" style={{ paddingBottom: '70px' }}>
          <div
            className="columns is-multiline images-grid"
            id="category-images"
          >
            <span
              className="interactive-grid-element"
              onClick={() => {
                downloadExportData();
              }}
            >
              {exportLoading ? (
                <div className="loader is-loading"></div>
              ) : (
                <>
                  <FontAwesomeIcon icon={icon({ name: "file-arrow-down" })} />
                  Export annotations
                </>
              )}
            </span>

            <Link
              to={"/images/new/" + selectedCategory}
              className="interactive-grid-element"
              replace={false}
            >
              <FontAwesomeIcon icon={icon({ name: "plus" })} />
            </Link>
            {images.map((image) => (
              <Link to={`#`} className="edit-image" key={image.id}>
                <img
                  src={process.env.REACT_APP_API_URL + image.small_image_url}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
