import { useState, createContext, Dispatch, SetStateAction, useContext, useEffect, useRef} from 'react';
import { image } from '../types/image';
import { annotationSettings } from '../types/annotationSettings';
import { fabric } from 'fabric';
import Canvas from '../components/annotate/canvas';
import Sidebar from '../components/annotate/sidebar';
import { useNavigate } from 'react-router-dom';

interface ImageContextType {
    currentImage: image | undefined;
    setCurrentImage: Dispatch<SetStateAction<image | undefined>>;
}

interface CanvasContextType {
    currentCanvas: fabric.Canvas | undefined;
    setCurrentCanvas: Dispatch<SetStateAction<fabric.Canvas | undefined>>;
}

interface AnnotationSettingsContextType {
    annotationSettings: annotationSettings | undefined;
    setAnnotationSettings: Dispatch<SetStateAction<annotationSettings | undefined>>;
}

const ImageContext = createContext<ImageContextType>({
    currentImage: undefined,
    setCurrentImage: () => { },
});

const CanvasContext = createContext<CanvasContextType>({
    currentCanvas: undefined,
    setCurrentCanvas: () => { },
});

const AnnotationSettingsContext = createContext<AnnotationSettingsContextType>({
    annotationSettings: undefined,
    setAnnotationSettings: () => { },
});


export default function Annotate() {
    const [currentImage, setCurrentImage] = useState<image | undefined>(undefined);
    const [currentCanvas, setCurrentCanvas] = useState<fabric.Canvas | undefined>(undefined);
    const [annotationSettings, setAnnotationSettings] = useState<annotationSettings | undefined>(undefined);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
          e.preventDefault();
          e.returnValue = "";
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <ImageContext.Provider value={{ currentImage: currentImage, setCurrentImage: setCurrentImage }}>
        <AnnotationSettingsContext.Provider value={{ annotationSettings: annotationSettings, setAnnotationSettings: setAnnotationSettings }}>
        <CanvasContext.Provider value={{ currentCanvas: currentCanvas, setCurrentCanvas: setCurrentCanvas }}>
                <div className="wrapper">
                    <Sidebar />
                    <main className="main">
                        {currentImage && <Canvas/> }
                    </main>
                </div>
        </CanvasContext.Provider>
        </AnnotationSettingsContext.Provider>
        </ImageContext.Provider>
    )
}

export const useImageContext = () => useContext(ImageContext);
export const useCanvasContext = () => useContext(CanvasContext);
export const useAnnotationSettingsContext = () => useContext(AnnotationSettingsContext);
