import LoginForm from '../components/loginForm';
import { userSignedIn } from '../services/auth.service';
import { Navigate } from "react-router-dom"
import { notify } from '../components/notification';
import 'bulma/css/bulma.min.css';

export default function Login() {
    if (userSignedIn()) {
        notify("You are already logged in", "info");
        return <Navigate to="/" replace />
    }
    return (
        <LoginForm />
    )
}
