import axios from 'axios';
import { getToken } from './auth.service';


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "users/",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use((config) => {
    const token: string | null = getToken();
    if (token) {
        config.headers.Authorization = token;
    } else {
        localStorage.removeItem("user");
        localStorage.removeItem("jwt");
        window.location.href = "/login";
    }
    return config;
});

export default instance;
