import axios from "axios";
import tokenizedAxios from "./tokenizedAxios.service";
import {user} from "../types/user";
const API_URL = process.env.REACT_APP_API_URL + "users/";

export function getToken() : string | null {
    if (tokenValid()){
        return JSON.parse(localStorage.getItem("jwt") as string);
    }
    return null;
};

export const login  = (email: string, password: string)=> {
    return axios
        .post(API_URL + "sign_in", {
            user: {
                email,
                password,
            }
        })
        .then((response) : {message: string} => {
            if (response.headers.authorization) {
                localStorage.setItem("jwt", JSON.stringify(response.headers.authorization));
                localStorage.setItem("user", JSON.stringify(response.data.user));
            }
            return response.data;
        });
};

export const logout = () => {
    return tokenizedAxios.delete("/sign_out").then((response) => {
        localStorage.removeItem("user");
        localStorage.removeItem("jwt");
        return response
    });
};

export const currentUser = () : user | null => {
    const user : string | null = localStorage.getItem("user");


    return user ? JSON.parse(user) : null;
};

export function tokenValid() : boolean {
    const token : string | null = localStorage.getItem("jwt");

    if (token) {
        const jwt = JSON.parse(token);
        const jwtData = jwt.split("Bearer ")[1].split(".")[1];
        const decodedJwtJsonData = window.atob(jwtData);
        const decodedJwtData = JSON.parse(decodedJwtJsonData.toString());

        const expirationDateInMs = decodedJwtData.exp * 1000;
        const todayDateInMs = new Date().getTime();

        if (expirationDateInMs > todayDateInMs) {
            return true;
        }else{
            logout();
            return false;
        }
    }

    return false;
};

export function userSignedIn() : boolean {
    return tokenValid();
};


