import { user } from '../../types/user'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { notify } from '../notification'
import { useUserContext } from '../../pages/users'
import UserForm from "./userForm"
import tokenizedAxios from '../../services/tokenizedAxios.service'
const strftime = require('strftime');

export default function User({ user }: { user: user}) {
    const [showModal, setShowModal] = useState(false);
    const {users, setUsers} = useUserContext();

    const deleteUser = (id: number) => {
        if (window.confirm("Are you sure. This action cannot be undone.")){
            tokenizedAxios.delete(`/users/${id}`).then((response) => {
                setUsers(users.filter((user) => user.id !== id));
                notify(response.data.message, "success");
            }).catch((error) => {
                notify("Error! User is not removed.", "error");
            });
        }
    }

    return (
        <>
        {showModal && <UserForm onClose={() => { setShowModal(false) }} userId={user.id} />}
        <tr key={user.id}>
            <td data-label="Id">
                {user.id}
            </td>
            <td data-label="Email">
                {user.email}
            </td>
            <td data-label="First name">
                {user.first_name}
            </td>
            <td data-label="Last name">
                {user.last_name}
            </td>
            <td data-label="Role">
                {user.is_admin ? "Admin" : "User"}
            </td>
            <td data-label="Created at">
                {strftime("%d/%m/%Y - %H:%M", new Date(user.created_at))}
            </td>
            <td data-label="Updated at">
                {strftime("%d/%m/%Y - %H:%M", new Date(user.updated_at))}
            </td>
            <td>
                <div className="buttons">
                    <button className="button is-small is-primary" onClick={() => {setShowModal(true)}}>
                        <span className="icon is-small">
                            <FontAwesomeIcon icon={icon({name: 'edit'})} />
                        </span>
                    </button>
                    <button className="button is-small is-danger" onClick={() => {deleteUser(user.id)}} >
                        <span className="icon is-small">
                            <FontAwesomeIcon icon={icon({name: 'trash'})} />
                        </span>
                    </button>
                </div>
            </td>

        </tr>
        </>
    )
}

