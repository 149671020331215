import { annotationOption } from "../../../types/annotationOption";
import { fabric } from "fabric";

declare module "fabric/fabric-impl" {
    interface Circle {
        /* fabric-history */
        undo: (callback?: () => void) => void;
        redo: (callback?: () => void) => void;
        clearHistory: () => void;
        historyNextState: any;
        _historyNext: () => any;
        annotation_option_id: number;
    }
}


interface KeyBindingsParams {
    annotationOption: annotationOption;
    shape: string;
    canvas: fabric.Canvas;
    opt: fabric.IEvent<MouseEvent>;
}

function toRGBA(hex: string, alpha: number): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const a = alpha;
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function addShape({ annotationOption, shape, canvas, opt }: KeyBindingsParams): void {
    const pointer = canvas.getPointer(opt.e);
    const activeObject = canvas.getActiveObject();
    const annotationOptionId = annotationOption.id;

    if (activeObject) {
        // If an existing object is clicked, skip creating a new one
        return;
    }
    const color = annotationOption.color;
    if (shape === 'circle') {
        addCircle({ pointer, color, canvas, annotationOptionId });
    }
}

function addCircle({ pointer, color, canvas, annotationOptionId }:
    { pointer: { x: number, y: number }; color: string; canvas: fabric.Canvas; annotationOptionId: number }) {
    const circle = new fabric.Circle({
        left: pointer.x,
        top: pointer.y,
        originX: 'center',
        originY: 'center',
        radius: 10,
        fill: toRGBA(color, 0.3),
        strokeWidth: 2,
        stroke: color,
        selectable: true,
        hasControls: true,
        lockScalingX: false,
        lockScalingY: false,
        hasRotatingPoint: true,
        strokeUniform: true,
    });
    circle.set("annotation_option_id", annotationOptionId);
    canvas.add(circle);
    canvas.setActiveObject(circle);
    canvas.renderAll();
}
